import { ThemeProvider } from "@emotion/react";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { buildTheme } from "../../theme";

interface LiteThemeContextType {
  darkMode: boolean;
  darkModeStatus: "dark" | "light" | "system";
  setDarkModeStatus: React.Dispatch<
    React.SetStateAction<"dark" | "light" | "system">
  >;
}

export const LiteThemeContext = createContext<LiteThemeContextType>({
  darkMode: false,
  darkModeStatus: "light",
  setDarkModeStatus: () => {},
});

export default function LiteThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Figure out what the user prefers
  const mediaQueryList = useMemo(
    () =>
      !import.meta.env.SSR
        ? window.matchMedia("(prefers-color-scheme: dark)")
        : undefined,
    []
  );
  const [systemIsSetToDarkMode, setSystemIsSetToDarkMode] = useState(
    mediaQueryList?.matches ?? false
  );
  // Listen for changes
  useEffect(() => {
    const updateDarkMode = () =>
      setSystemIsSetToDarkMode(mediaQueryList?.matches ?? false);
    mediaQueryList?.addEventListener("change", updateDarkMode);
    return () => mediaQueryList?.removeEventListener("change", updateDarkMode);
  }, [mediaQueryList]);

  const [darkModeStatus, setDarkModeStatus] = useState<
    "dark" | "light" | "system"
  >(localStorage.theme_override || "light");

  const [darkMode, setDarkMode] = useState(
    darkModeStatus === "system"
      ? systemIsSetToDarkMode
      : darkModeStatus === "dark"
  );

  useEffect(() => {
    if (darkModeStatus === "system") {
      setDarkMode(systemIsSetToDarkMode);
    } else {
      setDarkMode(darkModeStatus === "dark");
    }
  }, [darkModeStatus, systemIsSetToDarkMode]);

  useEffect(() => {
    localStorage.theme_override = darkModeStatus;
  }, [darkModeStatus]);

  return (
    <LiteThemeContext.Provider
      value={{
        darkMode,
        darkModeStatus,
        setDarkModeStatus,
      }}
    >
      <ThemeProvider theme={buildTheme(darkMode)}>{children}</ThemeProvider>
    </LiteThemeContext.Provider>
  );
}
